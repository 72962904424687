<template>
  <dashboard-content full-width>
    <page-header sticky>
      <h1 class="mb-4">
        Logs
      </h1>
    </page-header>
    <v-row>
      <v-col class="col-12 py-0">
        <v-select
          v-model="filterValue"
          :items="filterItems"
          label="Filtering by Type"
          name="type"
          @change="filterChangeHandler($event, 'type')"
        />

        <firebase-table
          :key="key"
          :headers="headers"
          :filters="filters"
          collection="logs"
        >
          <template v-slot:item.createdAt="{ item }">
            {{ formatDatetimeLocal(item.createdAt) }}
          </template>
          <template v-slot:item.action="{ item }">
            <v-icon
              small
              class="mr-2"
              @click="showItem(item.id)"
            >
              {{ mdiEye }}
            </v-icon>
          </template>
        </firebase-table>
      </v-col>
    </v-row>
  </dashboard-content>
</template>

<script>
import { mdiEye } from '@mdi/js'
import DashboardContent from '@/components/dashboard/DashboardContent'
import FirebaseTable from '@/components/FirebaseTable'
import PageHeader from '@/components/dashboard/PageHeader.vue'

export default {
  components: { DashboardContent, FirebaseTable, PageHeader },
  data() {
    return {
      mdiEye,
      headers: [
        { text: 'Created at', value: 'createdAt' },
        { text: 'Type', value: 'type' },
        { text: 'Session ID', value: 'sessionId' },
        { text: 'Function', value: 'functionName' },
        { text: 'Message', value: 'message' },
        { text: 'Actions', value: 'action', sortable: false }
      ],
      filterValue: '',
      filters: {},
      filterItems: [
        { text: 'All', value: '' },
        { text: 'Info', value: 'info' },
        { text: 'Error', value: 'error' }
      ],
      key: 1
    }
  },
  mounted() {
    Object.keys(this.$route.query).forEach(item => {
      const key = this.toCamelCase(item)

      this.filterValue = this.$route.query[item]
      this.filters = {
        ...this.filters,
        [key]: this.$route.query[item]
      }
    })
  },
  methods: {
    filterChangeHandler(value, type) {
      const key = this.toCamelCase(type)

      this.filters =
        value !== ''
          ? {
              ...this.filters,
              [key]: value
            }
          : {}

      this.key++
    },
    toCamelCase(value) {
      return value.replace(/([-_][a-z])/gi, m =>
        m.toUpperCase().replace('-', '')
      )
    },
    showItem(id) {
      this.$router.push({ name: 'logShow', params: { id } })
    }
  }
}
</script>
